import { useStaticQuery, graphql } from 'gatsby';

export const UseContentfulWeb = ():ContentfulWeb => {
    const { contentfulWeb } = useStaticQuery(
      graphql`
      query HelmetQuery {
        contentfulWeb {
          name
          description             
          bannerText {
            json
          }                 
          profile {
            favicon16: resize(width: 16) {
              src
            }
            favicon32: resize(width: 32) {
              src
            }
            bigIcon: resize(width: 192) {
              src
            }
            appleIcon: resize(width: 180) {
              src
            }
          }
          bannerImage {
            image: resize {
              src
            }
          }
        }
      }
      `
    );

    return contentfulWeb;
}

export interface ContentfulWeb {
    name: string,
    description: string,
    profile: Profile,
    bannerImage: BannerImage,
    bannerText: {
      json?: string
    }     
}

export interface BannerImage {
  image: {
    src: string;
  }
}

export interface Profile {
    favicon16: {
      src: string;
    },  
    favicon32: {
      src: string;
    },
    bigIcon: {
      src: string;
    },
    appleIcon: {
      src: string;
    },  
}