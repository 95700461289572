import React from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { UseContentfulWeb, ContentfulWeb } from '@hooks/UseContentfulWeb';

const Helmet = ({ theme = {} }) => {

  const contentful: ContentfulWeb = UseContentfulWeb();

  return (
        <ReactHelmet htmlAttributes={{ lang: 'es' }}>
          <meta charSet="utf-8" />
          {/*
          <title>{title}</title>
          <meta name="description" content={description} />
          <link rel="shortcut icon" href={`https:${profile.favicon32.src}`} />
          <meta name="theme-color" content={theme.background} />
          <meta name="image" content={`https:${profile.favicon32.src}`} />
          <meta itemProp="name" content={title} />
          <meta itemProp="description" content={description} />
          <meta itemProp="image" content={`https:${profile.favicon32.src}`} />
          <meta name="og:title" content={title} />
          <meta name="og:description" content={description} />
          <meta name="og:image" content={`https:${profile.bigIcon.src}`} />
          <meta name="og:site_name" content={title} />
          <meta name="og:locale" content="en_US" />
          <meta name="og:type" content="website" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:image" content={`https:${profile.bigIcon.src}`} />
          */}
          <meta
            name="twitter:image:src"
            content={`https:${contentful.profile.bigIcon.src}`}
          />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href={`https:${contentful.profile.appleIcon.src}`}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href={`https:${contentful.profile.favicon32.src}`}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href={`https:${contentful.profile.favicon16.src}`}
          />
          <link
            href='https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800&display=swap'
            rel='stylesheet'
          />     
          <link 
            href="https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" 
            rel="stylesheet"/>
          <link 
            href="https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,300;1,400;1,500&display=swap" 
            rel="stylesheet"/>             
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
          />               
        </ReactHelmet>
      );
}

Helmet.propTypes = {
  // eslint-disable-next-line
  theme: PropTypes.object,
};

export default withTheme(Helmet);
